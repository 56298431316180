import { AgentPageModel, ApiResponse } from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const agentActions = createActionGroup({
  source: 'Agent page',
  events: {
    'fetch Agents': emptyProps(),
    'fetch Agents success': props<{
      data: ApiResponse<AgentPageModel.AgentAnalytics>;
    }>(),
    'export to csv': emptyProps(),
    'update filter': props<{ filter: AgentPageModel.filter }>(),
  },
});
export default agentActions;
